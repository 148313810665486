import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const App = () => {
  const [documents, setDocuments] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [columns, setColumns] = useState(getColumns(window.innerWidth));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    fetch('https://canada.aipnavigator.com/api/')
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => {
          if (b.sup_year !== a.sup_year) {
            return b.sup_year - a.sup_year;
          }
          return b.sup_sequence - a.sup_sequence;
        });
        setDocuments(sortedData);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setColumns(getColumns(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getColumns(width) {
    const isSmallScreen = width < 600; // Define the width threshold for small screens

    return [
      ...(isSmallScreen ? [] : [
        {
          field: 'sup_year',
          headerName: 'Year',
          align: 'center',
          headerAlign: 'center',
          type: 'number',
          valueFormatter: (params) => params.value?.toString()
        },
        {
          field: 'sup_sequence',
          headerName: 'Sequence',
          align: 'center',
          headerAlign: 'center',
          type: 'number'
        }
      ]),
      {
        field: 'sup_header',
        headerName: 'Title',
        flex: 1,
        renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', lineHeight: 'normal', overflowWrap: 'break-word', padding:'5px' }}>
            <a href={params.row.custom_s_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              {params.value}
            </a>
          </div>
        )
      }
      // Add other columns as needed
    ];
  }


  const filteredDocuments = documents.filter(doc => {
    const yearStr = doc.sup_year != null ? doc.sup_year.toString() : '';
    const sequenceStr = doc.sup_sequence != null ? doc.sup_sequence.toString() : '';
    const headerStr = doc.sup_header != null ? doc.sup_header.toLowerCase() : '';
    const custom_dt_created = doc.custom_dt_created;

    return sequenceStr.length>0 && (yearStr.includes(searchText) ||
           sequenceStr.includes(searchText) ||
           headerStr.includes(searchText.toLowerCase()));
  });

  const handleClearSearch = () => {
    setSearchText('');
  };

  return (
    <div style={{ width: '100%', padding: '20px' }}>

      <h2 style={{ padding: '20px' }}>AIP Supplements Canada</h2>

      <div className="alert alert-danger" style={{ marginBottom: '20px' }}>
        The authoritative source of aeronautical information in Canada is NAV CANADA. Pilots are advised to obtain all
        pertinent pre-flight information from Flight Information Centres / Flight Service Stations (FIC/FSS) prior to
        flying. The user accepts sole responsibility and all risks associated with use of the information on this web
        site.
      </div>

      <div className="alert alert-info" style={{marginBottom: '20px'}}>
        For a single downloadable document containing all published AIP Supplements,   <a href="http://canada.aipnavigator.com/documents/compiled_document.pdf" target="_blank" rel="noopener noreferrer">click here</a>.
.
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}> {/* Ensure alignment of search field and button */}
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button variant="contained" onClick={handleClearSearch} style={{ marginLeft: '10px' }}>
            Clear
          </Button>
        </div>
        <Typography variant="subtitle1" style={{ marginLeft: 'auto', paddingLeft: '20px', fontSize:'small'}}>
          {filteredDocuments.length} out of {documents.length} results
        </Typography>
      </div>


      <DataGrid
        rows={filteredDocuments}
        columns={columns}
        autoHeight
        hideFooter
        getRowClassName={(params) => {
          const creationDate = new Date(params.row.custom_dt_created);
          const twoWeeksAgo = new Date();
          twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

          return creationDate > twoWeeksAgo ? 'rowHighlight' : '';
        }}
        getRowHeight={(params) => {
          // Logic to determine row height based on content
          // For instance, you might calculate the height based on the number of line breaks
          return 'auto'; // or a calculated height
        }}
      />
    </div>
  );
};

export default App;
